@import '../../../assets/sass/variables';
.loading {
  align-items: center;
  color: $black;
  display: flex;
  font-size: 2rem;
  font-weight: $bold;
  height: var(--full-height);
  justify-content: center;
}
