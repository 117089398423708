@import './assets/sass/variables.scss';
html {
  box-sizing: border-box;
  font-size: 62.5%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

@for $i from 1 through 6 {
  h#{$i} {
    font-size: 1.6rem;
  }
}

body {
  font-family: $baseFont;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: $black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, 'Courier New', monospace;
}

button,
input,
textarea {
  font-size: 1.6rem;
}

@font-face {
  font-family: antenna;
  font-style: normal;
  font-weight: 100;
  src: url(./fonts/antenna/FordAntenna-ExtraLight.otf);
}

@font-face {
  font-family: antenna;
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/antenna/FordAntenna-Light.otf);
}

@font-face {
  font-family: antenna;
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/antenna/FordAntenna-Regular.otf);
}

@font-face {
  font-family: antenna;
  font-style: italic;
  font-weight: 500;
  src: url(./fonts/antenna/FordAntenna-RegularItalic.otf);
}

@font-face {
  font-family: antenna;
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/antenna/FordAntenna-Bold.otf);
}

@font-face {
  font-family: antenna;
  font-style: italic;
  font-weight: 700;
  src: url(./fonts/antenna/FordAntenna-BoldItalic.otf);
}

@font-face {
  font-family: antennaCondensed;
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/antenna_condensed/FordAntennaCond-Regular.otf);
}

@font-face {
  font-family: antennaCondensed;
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/antenna_condensed/FordAntennaCond-Bold.otf);
}

@font-face {
  font-family: antennaCondensedBlack;
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/antenna_condensed/FordAntennaCond-Black.otf);
}

svg {
  fill: currentColor;
}
