@import 'variables';
@mixin media--over-xs {
  @media only screen and (min-width: $xs-min) {
    @content;
  }
}
@mixin media--over-sm {
  @media only screen and (min-width: $sm-min) {
    @content;
  }
}
@mixin media--over-md {
  @media only screen and (min-width: $md-min) {
    @content;
  }
}
@mixin media--over-lg {
  @media only screen and (min-width: $lg-min) {
    @content;
  }
}
@mixin media--over-xl {
  @media only screen and (min-width: $xl-min) {
    @content;
  }
}

@mixin media--over-xxl {
  @media only screen and (min-width: $xxl-min) {
    @content;
  }
}

@mixin media--over-xxl {
  @media only screen and (min-width: $xxxl-min) {
    @content;
  }
}

@mixin media--portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin media--landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin dimensions($width, $height: $width) {
  height: $height;
  width: $width;
}
@mixin circle($diameter) {
  @include dimensions($diameter);
  border-radius: 50%;
}
