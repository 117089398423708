@import '../../../assets/sass/mixins';
@import '../../../assets/sass/variables';
.cookiesPolicyV2 {
  color: $white;
  line-height: 1.6em;
  position: fixed;
  width: 100%;
  z-index: 11;
  height: 100%;
  align-items: center;
  padding: 0 20px;
  overflow-y: auto;

  a {
    color: $white;
  }
}

.cookiesPolicyV2_container {
  width: 100%;
  height: 100%;
}

.cookiesPolicyV2_container__hidden {
  display: none;
}

.cookiesPolicyV2_overlay {
  background-color: $midnight;
  position: fixed;
  width: 100%;
  z-index: 10;
  opacity: 0.8;
  height: 100%;
}

.cookiesPolicyV2_logo {
    max-width: 440px;
    text-align: center;
    margin: 65px auto 0 auto;

    svg {
      width: 184px;
      height: 92px;
    }
    
    @include media--over-xs {
      svg {
        width: 228px;
        height: 114px;
      }
    }
}

.cookiesPolicyV2_heading {
    max-width: 310px;
    text-align: center;
    margin: auto;
    font-size: 1.3rem;
    font-weight: $bold;
    
    @include media--over-xs {
      max-width: 440px;
    }
}

.cookiesPolicyV2_ctas {
  height:300px;
  max-width: 310px;
  margin: 40px auto 0 auto;
  text-align: center;

  button {
    max-width: 310px;
    width: 100%;
    height: 70px;
    padding: 16px 37px;
    background-color: #f4f5f8;
    font-family: $baseFontCond;
    border: none;
    color: $midnight;
    font-weight: $bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.cookiesPolicyV2_ctas__hidden {
  display:none;
}

.cookiesPolicyV2_footer {
  max-width: 270px;
  text-align: center;
  margin:auto;
  line-height: 1.69;
  font-weight: $light;
  font-size: 1.3rem;

  p {
    margin-bottom: 25px;
  }
}

.cookiesPolicyV2_switchHeading {
  font-size: 0.9rem;
  font-weight: $bold;
}

.cookiesPolicyV2_switchContainer {
  margin-bottom: 20px;
  text-transform: uppercase;

  :global(.MuiSwitch-root) {
    padding: 2px;
    width: 62px;
    height: 26px;
    border-radius: 13px;
    margin: 10px 0;
    border: solid 2px #fff;
  } 

  :global(.MuiSwitch-switchBase) {
    padding: 2px;
  }

  :global(.MuiSwitch-track) {
    border-radius: 10px;
  }
  
  :global(.MuiSwitch-thumb) {
    width: 18px;
    height: 18px;
  }
  
  :global(.MuiSwitch-switchBase.Mui-checked) {
    right: 11px;
  }
}