@import '../../../assets/sass/variables';
.nextPage {
  color: $midnight;
  font-size: 1.3rem;
  font-weight: $regular;
  padding: 22px 0 8px 0;
  text-align: center;
  text-transform: $case;
  width: 100%;
  font-family: $baseFontCond;
}

.nextPage_alt {
  color: $white;
}

.nextPage__text {
  color: inherit;
  text-decoration: none;

  &::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid;
    content: '';
    display: block;
    margin: 10px auto 0;
    width: 0;
  }
}
