@import '../../../assets/sass/mixins';
@import '../../../assets/sass/variables';
.cookiesPolicy {
  background-color: $midnight;
  top: 0;
  color: $off-white;
  font-size: 1.1rem;
  left: 0;
  line-height: 1.6em;
  padding: 30px 100px 30px 30px;
  position: fixed;
  width: 100%;
  z-index: 10;
  opacity: 0.9;
  min-height: 116px;
  display: flex;
  align-items: center;

  a {
    color: $white;
  }
}

.cookiesPolicy_hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity $base-transition,
    visibility $base-transition $base-transition-time,
    z-index $base-transition $base-transition-time;
  visibility: hidden;
  z-index: -1;
}

.cookiePolicy__close {
  cursor: pointer;
  font-size: 32px;
  position: fixed;
  right: 15px;
  top: 30px;
  z-index: 2;
  background-color:$midnight;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  z-index: 10;
  right: 21px;
  top: 29px;

  @include media--over-md {
    right: 21px;
    top: 29px;
  }

  &.cookiePolicy__close_hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity $base-transition,
      visibility $base-transition $base-transition-time,
      z-index $base-transition $base-transition-time;
    visibility: hidden;
    z-index: -1;
  }
}

.cookiesPolicy_button {
  height: 56px;
  width: 56px;
}