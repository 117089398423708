$white: #fff;
$off-white: #d8d8d8;
$black: #000;
$light-grey: #bababa;
$grey: #8a8a8a;
$menu-grey: #f4f5f8;
$medium-grey: #595959;
$dark-grey: #424242;
$red: #d55;
$midnight: #00095B;
$sky: #1700F4;
$form-element-height: 40px;
$cta-hover: #1400d0;
$menu-hover: #dcdfe4;

// padding
$default-padding: 20px;

// transition
$base-transition-time: 0.3s;
$base-transition: $base-transition-time ease-out;
$xxs-min: 450px;
$xs-min: 600px;
$sm-min: 750px;
$md-min: 900px;
$lg-min: 1024px; // Concession for ipad
$xl-min: 1200px;
$xxl-min: 1350px;
$xxxl-min: 1500px;
$max-width: $xl-min;

// Sizes
$article-menu-dot-size: 8px;
$content-width-side: 330px;
$content-box-padding: 25px 50px 0 25px;
$page-details-height: 115px;

// Fonts
$baseFont: 'antenna', sans-serif;
$baseFontCond: 'antennaCondensed', sans-serif;
$baseFontCondBlack: 'antennaCondensedBlack', sans-serif;
// Font weights
$extraLight: 100;
$light: 300;
$regular: 500;
$bold: 700;

// Text casing
$case: uppercase;

:root {
  --full-height: 100vh;
}
