@import '../../assets/sass/mixins';
@import '../../assets/sass/variables';

.landing__bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: var(--full-height);
  text-align: center;
  text-decoration: none;
}

.landing__container {
  background-color: $black;
} 

.landing__logo {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 100%;
}

.landing__oval {
  display: block;
  margin: auto;

  @include media--landscape {
    width: 120px;
    @include media--over-md {
      width: 200px;
    }
  }

  @include media--portrait {
    width: 200px;
  }
}

.landing__content {
  position: absolute;
  top: 15%;
  width: 100%;
  z-index: 1;

  @include media--portrait {
    top: 5%;

    @include media--over-sm {
      top: 15%;
    }
  }
}

.landing__heading {
  font-family: $baseFontCondBlack;
  color: #ffffff;
  text-transform: $case;
  margin-left: 15%;
  margin-right: 15%;

  @include media--landscape {
    font-size: 4.2rem;

    @include media--over-md {
      font-size: 6.2rem;
    }
  }

  @include media--portrait {
    font-size: 4.2rem;

    @include media--over-sm {
      font-size: 6.2rem;
    }
  }
}

.landing__cta {
  bottom: 20px;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
}

.landing__ctaLink {
  text-decoration: none;
}

.landing__videoBg {
  height: 100%;
  position: absolute;
  width: 100%;
}
